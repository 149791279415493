import Link from 'next/link';

import Error404 from '~components/ui/Error404';
import Layout from '~components/ui/Layout';

import s from '../styles/pages/CustomErrorPage.module.css';

export default function Custom404() {
  return (
    <Layout meta={{ title: 'Error 404' }}>
      <div className={s.root}>
        <Error404 />
        <h1 className={s.errorTitle}>¿Estás perdido?</h1>
        <p className={s.errorCopy}>
          Esta es una página 404. Eso significa que no pudimos encontrar lo que estas buscando.
          Pero, no te rindas, sigue buscando en el mar.{' '}
        </p>
        <Link href="/" className="btn btn-secondary btn-lg">
          Ir a la cartelera
        </Link>
      </div>
    </Layout>
  );
}
